// https://developers.google.com/web/fundamentals/web-components/best-practices
const style = `
  @keyframes horizontal-marquee {
    from {
      transform: translateX(var(--horizontal-marquee-position-start));
    }

    to {
      transform: translateX(var(--horizontal-marquee-position-end));
    }
  }

  :host {
    --horizontal-marquee-position-start: 0%;
    --horizontal-marquee-position-end: calc(-100% / 4);
    --horizontal-marquee-play-state: paused;
    --horizontal-marquee-delay: 0s;
    --horizontal-marquee-duration: 20s;

    display: block;
  }

  :host([hidden]) { display: none }

  #container {
    display: flex;
    flex-wrap: nowrap;
    will-change: transform;

    animation-name: horizontal-marquee;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    animation-duration: var(--horizontal-marquee-duration);
    animation-play-state: var(--horizontal-marquee-play-state);
    animation-delay: var(--horizontal-marquee-delay);
  }

  :host([running]) {
    --horizontal-marquee-play-state: running !important;
  }

  :host([paused]) {
    --horizontal-marquee-play-state: paused !important;
  }
`

class HorizontalMarquee extends HTMLElement {
  constructor() {
    super()

    const shadowRoot = this.attachShadow({ mode: "open" })
    shadowRoot.innerHTML = `
      <style>${style}</style>
      <div id="container"><slot /></div>
    `
  }

  connectedCallback() {
    if (this.autoplay) this.start()
  }

  disconnectedCallback() {}

  start() {
    void this.offsetWidth
    this._unpause()
    this.setAttribute("running", "")
  }

  stop() {
    this._unpause()
    this.removeAttribute("running")
  }

  pause() {
    this.removeAttribute("running")
    this.setAttribute("paused", "")
  }

  _unpause() {
    this.removeAttribute("paused")
  }

  restart() {
    this.stop()
    this.start()
  }

  get autoplay() {
    return this.hasAttribute("autoplay")
  }
}

customElements.define("horizontal-marquee", HorizontalMarquee)
