import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    title: String,
    statusUrl: String,
    src: String
  }
  static targets = ["audio"]
  static classes = ["playing"]

  onPlay() {
    this.element.classList.add(this.playingClass)
  }

  onPause() {
    this.element.classList.remove(this.playingClass)
    this.audioTarget.src = this.src
  }

  toggle() {
    if (this.audioTarget.paused) {
      this.audioTarget.src = this.src
      this.audioTarget.play()
    } else {
      this.audioTarget.pause()
    }
  }

  updateTitle({ detail: { titles } }) {
    if (!titles) return
    const combinedTitles = Object.values(titles).join(" / ")
    this.audioTarget.title = ["Radio Sfæren", combinedTitles].flat().join(": ")
  }

  get src() {
    return `${this.srcValue}?nocache=${Date.now()}`
  }

  get playing() {
    return !this.paused
  }

  get paused() {
    return this.audioTarget.paused
  }
}
