import { Controller } from "@hotwired/stimulus"
import Chart from "chart.js/auto"

export default class extends Controller {
  static values = { type: String, labels: Array, datasets: Array }

  COLORS = ["#999", "#888", "#777", "#666", "#555"]

  connect() {
    this.chart = new Chart(this.element, {
      type: this.typeValue,
      data: {
        labels: this.labelsValue,
        datasets: [
          {
            data: this.datasetsValue,
            backgroundColor: Object.values(this.COLORS)
          }
        ]
      }
    })
  }
}
