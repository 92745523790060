import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["title", "marquee"]
  static values = {
    name: String,
    title: String,
    paused: Boolean
  }
  static classes = ["empty"]

  onTitles({ detail: { titles } }) {
    if (!titles) return
    if (!this.nameValue) return
    const title = titles[this.nameValue]
    this.titleValue = title
  }

  titleValueChanged() {
    this.pausedValue = true
    this.element.classList.toggle(this.emptyClass, this.titleValue == "")
    this.titleTargets.forEach((target) => {
      target.textContent = this.decodeHtml(this.titleValue)
    })
    setTimeout(() => (this.pausedValue = false), 1000)
  }

  pausedValueChanged() {
    if (!this.pausedValue) {
      this.marqueeTarget.start()
    } else {
      this.marqueeTarget.pause()
    }
  }

  decodeHtml(html) {
    const txt = document.createElement("textarea")
    txt.innerHTML = html
    return txt.value
  }
}
