import { Controller } from "@hotwired/stimulus"

function supportsIntersectionObserver() {
  return (
    "IntersectionObserver" in window ||
    "IntersectionObserverEntry" in window ||
    "intersectionRatio" in window.IntersectionObserverEntry.prototype
  )
}

export default class extends Controller {
  static targets = ["scrollArea", "dot", "section"]
  static values = { currentIndex: Number }

  connect() {
    this.startObservingColumnVisibility()
  }

  startObservingColumnVisibility() {
    if (!supportsIntersectionObserver()) {
      console.warn(`This browser doesn't support IntersectionObserver`)
      return
    }

    this.intersectionObserver = new IntersectionObserver(
      this.updateScrollNavigation.bind(this),
      {
        root: this.scrollAreaTarget,
        threshold: 0.5
      }
    )

    this.sectionTargets.forEach((section) => {
      this.intersectionObserver.observe(section)
    })
  }

  updateScrollNavigation(observerRecords) {
    observerRecords.forEach((record) => {
      record.target.ariaCurrent = record.isIntersecting
    })
    const currentSection = this.sectionTargets.find(
      (s) => s.ariaCurrent === "true"
    )
    this.currentIndexValue = this.sectionTargets.indexOf(currentSection)
  }

  set currentDot(dot) {
    this.dotTargets.forEach((d) => {
      d.ariaCurrent = false
    })
    dot.ariaCurrent = true
  }

  scrollToSection({ params: { index } }) {
    this.sectionTargets[index].scrollIntoView({
      behavior: "smooth",
      inline: "start"
    })
  }

  currentIndexValueChanged() {
    this.dotTargets.forEach((dot) => {
      dot.ariaCurrent = false
    })
    const dot = this.dotTargets[this.currentIndexValue]
    if (dot) dot.ariaCurrent = true
  }

  disconnect() {
    this.stopObservingColumnVisibility()
  }

  stopObservingColumnVisibility() {
    if (this.intersectionObserver) {
      this.intersectionObserver.disconnect()
    }
  }
}
