import { Controller } from "@hotwired/stimulus"
import Solar from "js/solar"

export default class extends Controller {
  static targets = ["themeColorMeta", "favicon"]

  connect() {
    this.updateColor()
    this.interval = setInterval(() => {
      this.updateColor()
    }, 1000)
  }

  disconnect() {
    clearInterval(this.interval)
  }

  updateColor() {
    this.updateThemeColor()
    this.updateFavicon()
    this.element.style.setProperty("--solar-color", this.color)
  }

  updateThemeColor() {
    if (!this.hasThemeColorMetaTarget) return
    this.themeColorMetaTarget.setAttribute("content", this.color)
  }

  updateFavicon() {
    if (!this.hasFaviconTarget) return
    let url = new URL(this.faviconTarget.href)
    url.searchParams.set("color", this.color)
    this.faviconTarget.href = url
  }

  get color() {
    return new Solar(this.now).color
  }

  get now() {
    return new Date()
  }
}
