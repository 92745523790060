import ColorScale from "color-scales"
import SunCalc from "suncalc"

export default class Solar {
  latitude = 55.56568
  longitude = 9.75257
  colorRangeMin = 0
  colorRangeMax = 200

  colors = {
    night: "#464650",
    sunset: "#8c4b19",
    solarNoon: "#d8d88c",
    sunrise: "#5c7f8a"
  }

  constructor(date = new Date()) {
    this.date = date
  }

  get color() {
    if (this.scaleStartColor === this.scaleEndColor) return this.scaleStartColor
    return this.colorScale
      .getColor(this.currentColorRangePosition)
      .toHexString()
  }

  get colorScale() {
    return new ColorScale(this.colorRangeMin, this.colorRangeMax, [
      this.scaleStartColor,
      this.scaleEndColor
    ])
  }

  get scaleStartColor() {
    if (this.timeStart === this.sunset.getTime()) {
      return this.colors.night
    } else if (this.timeStart === this.sunsetStart.getTime()) {
      return this.colors.sunset
    } else if (this.timeStart === this.solarNoon.getTime()) {
      return this.colors.solarNoon
    } else if (this.timeStart === this.sunrise.getTime()) {
      return this.colors.sunrise
    } else {
      return this.colors.night
    }
  }

  get scaleEndColor() {
    if (this.timeEnd === this.dawn.getTime()) {
      return this.colors.night
    } else if (this.timeEnd === this.sunrise.getTime()) {
      return this.colors.sunrise
    } else if (this.timeEnd === this.solarNoon.getTime()) {
      return this.colors.solarNoon
    } else if (this.timeEnd === this.sunsetStart.getTime()) {
      return this.colors.sunset
    } else {
      return this.colors.night
    }
  }

  get timeStart() {
    return this.dateStart.getTime()
  }

  get timeEnd() {
    return this.dateEnd.getTime()
  }

  get dateStart() {
    if (this.date >= this.sunset) {
      return this.sunset
    } else if (this.date >= this.sunsetStart) {
      return this.sunsetStart
    } else if (this.date >= this.solarNoon) {
      return this.solarNoon
    } else if (this.date >= this.sunrise) {
      return this.sunrise
    } else if (this.date >= this.dawn) {
      return this.dawn
    } else {
      return this.dawn
    }
  }

  get dateEnd() {
    if (this.date <= this.dawn) {
      return this.dawn
    } else if (this.date <= this.sunrise) {
      return this.sunrise
    } else if (this.date <= this.solarNoon) {
      return this.solarNoon
    } else if (this.date <= this.sunsetStart) {
      return this.sunsetStart
    } else if (this.date <= this.sunset) {
      return this.sunset
    } else {
      return this.dawn
    }
  }

  get currentColorRangePosition() {
    const now = this.date.getTime() - this.dateStart
    const end = this.dateEnd - this.dateStart
    const progress = now / end
    return Math.ceil(this.colorRangeMax * progress)
  }

  get sunTimes() {
    return SunCalc.getTimes(this.date, this.latitude, this.longitude)
  }

  get sunrise() {
    return this.sunTimes.sunrise
  }

  get sunsetStart() {
    return this.sunTimes.sunsetStart
  }

  get solarNoon() {
    return this.sunTimes.solarNoon
  }

  get sunset() {
    return this.sunTimes.sunset
  }

  get night() {
    return this.sunTimes.night
  }

  get dawn() {
    return this.sunTimes.dawn
  }
}
