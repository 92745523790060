import { Controller } from "@hotwired/stimulus"
import Solar from "js/solar"

export default class extends Controller {
  static values = { createdAt: String }

  connect() {
    this.element.style.setProperty("--solar-color", this.solarColor)
  }

  get solarColor() {
    return new Solar(this.createdAt).color
  }

  get createdAt() {
    return new Date(this.createdAtValue)
  }
}
