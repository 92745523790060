import { Controller } from "@hotwired/stimulus"
import { useDispatch } from "stimulus-use"

export default class extends Controller {
  static values = { title: String, statusUrl: String }
  static classes = ["started"]

  connect() {
    useDispatch(this)
    setTimeout(() => {
      this.element.classList.add(this.startedClass)
    }, 2000)
    this.interval = setInterval(() => {
      fetch(this.statusUrlValue)
        .then((response) => response.json())
        .then((data) => (this.titleValue = data.icestats.source.title))
    }, 1000)
  }

  disconnect() {
    clearInterval(this.interval)
  }

  titleValueChanged() {
    const titles = this.titleValue.split(" / ")
    this.dispatch("titles", {
      titles: {
        voice: titles[0],
        spatial: titles[1],
        music: titles[2],
        ambience: titles[3]
      }
    })
  }
}
